import React from "react";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const SendCV = loadable(() =>
    import("@components/Careers/components/SendCV/SendCV")
);
const SEO = loadable(() => import("@components/Common/SEO/SEO"));

const SendCVPage = props => {
    const { path, location } = props;
    let vacancyId;
    if (location.state) {
        vacancyId = location.state.vacancyId;
    }

    return (
        <>
            <SEO
                title="Send CV - Impressit"
                titleTemplate="Impressit"
                description="Send us your CV!"
                path={path}
                isNotIndexed
            />
            <Layout path={path}>
                <SendCV vacancyId={vacancyId} />
            </Layout>
        </>
    );
};

export default SendCVPage;
